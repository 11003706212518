export const reviews = [
  {
    authorPhoto: {
      src: 'https://media-exp1.licdn.com/dms/image/C4E03AQGGeyTyWtnLrQ/profile-displayphoto-shrink_400_400/0/1538123140563?e=1633564800&v=beta&t=BfZRI378nGL2bs_6jXZ0kOiPVahUnFYzJJeJgMgM-N0',
      srcSet: 'https://media-exp1.licdn.com/dms/image/C4E03AQGGeyTyWtnLrQ/profile-displayphoto-shrink_400_400/0/1538123140563?e=1633564800&v=beta&t=BfZRI378nGL2bs_6jXZ0kOiPVahUnFYzJJeJgMgM-N0'
    },
    authorName: 'Charles-Louis de Lovinfosse',
    authorOccupation: 'Guns for Hire, Artistic Director & Event Director',
    feedback:
      'We use Qeerio Production book on every single event. Every provider has a clear view on the event and his missions. This saves everybody a lot of time we generally don’t have during an event.'
  },
  {
    authorPhoto: {
      src: 'https://media-exp1.licdn.com/dms/image/C4D03AQH5dXb7NKv6uA/profile-displayphoto-shrink_400_400/0/1603139582630?e=1634169600&v=beta&t=HfbdMLy5tyuRz7pvfVRC903m6psDAnF5sexhMqrFoxw',
      srcSet: 'https://media-exp1.licdn.com/dms/image/C4D03AQH5dXb7NKv6uA/profile-displayphoto-shrink_400_400/0/1603139582630?e=1634169600&v=beta&t=HfbdMLy5tyuRz7pvfVRC903m6psDAnF5sexhMqrFoxw'
    },
    authorName: 'Annabelle Mekari',
    authorOccupation: 'Profirst, Project Coordinator',
    feedback:
      'With Qeerio Production book everybody can find the most appropriate person to contact instantly when there is a question about anything. Qeerio has become THE everyday app of our event managers.'
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
    },
    authorName: 'Jack Smith',
    authorOccupation: 'Head of Operations, Parkfield Commerce',
    feedback:
      'Everything is in the app!  No more printing and carrying all informations on event. All vital event information is in the pocket. We also share some key sections with our clients so that they can be reassured and follow how the event is going.'
  },
];

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  textWhite: {
    color: 'white',
    textTransform: 'uppercase'
  },
  textOrange: {
    color: 'rgb(255,100,83)',
    textTransform: 'uppercase'
  }
}));

const Professional = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <SectionHeader
        title={<span className={classes.textWhite}>A <span className={classes.textOrange}>simple pricing</span> based on your needs!</span>}
        titleVariant='h3'
        subtitleVariant='h4'
        subtitle={<span className={classes.textWhite}>Try it for <span className={classes.textOrange}>free</span></span>}
        ctaGroup={[
          <Box mt={3}>
            <Button variant='outlined' color='secondary' size='large' href="/inventory/pricing" aria-label="Discover our pricing">
                Discover our pricing
            </Button>
          </Box>
        ]}
        disableGutter
        data-aos='fade-up'
      />
    </div>
  );
};

Professional.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string
};

export default Professional;

import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { colors, Divider, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import {
  Section
} from 'components/organisms';
import {
  ParallaxProfesionnal,
  ParallaxFeature,
  ParallaxFirstImpression,
  ParallaxPricing
} from 'components/organisms/inventory';
import {
  IntroMd,
  Intro,
  Professional,
  FastCheckIn,
  Features,
  FastManagement,
  Pricing,
  FirstImpression,
  Reviews,
  Contact
} from './components';

import { reviews } from './data';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%'
  },
  fullHeight: {
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  disablePaddingTop: {
    paddingTop: 0
  },
  scrollIcon: {
    background: 'transparent !important',
    border: `2px solid ${colors.amber[500]}`,
    cursor: 'pointer'
  },
  madcapSection: {
    backgroundColor: 'rgba(0,0,0, 0.5)'
  },
  adidasSection: {
    backgroundColor: 'rgba(0,0,0, 0.3)'
  },
  firstImpressionSection: {
    backgroundColor: 'rgba(0,0,0, 0.75)'
  }
}));

const Home = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });

  // const scrollTo = id => {
  //   setTimeout(() => {
  //     const element = document.querySelector(`#${id}`);
  //     if (!element) {
  //       return;
  //     }

  //     window.scrollTo({ left: 0, top: element.offsetTop, behavior: 'smooth' });
  //   });
  // };
  return (
    <div className={classes.root}>
      <div className={clsx(classes.fullHeight, classes.adidasSection)}>
        <Section className={classes.disablePaddingTop}>
          {isMd ? <Intro /> : <IntroMd />}
        </Section>
      </div>
      <ParallaxFirstImpression
        backgroundImage={'../../../assets/images/qeerio/background2.1.jpg'}
        id="qeerio-firstimpressions"
      >
        <div className={clsx(classes.fullHeight, classes.firstImpressionSection)}>
          <Section>
            <FirstImpression />
          </Section>
        </div>
      </ParallaxFirstImpression>
      <div className={clsx(classes.fullHeight, classes.adidasSection)}>
        <Section>
          <FastManagement />
        </Section>
      </div>
      <ParallaxFeature
        backgroundImage={'../../assets/images/qeerio/testpreview.png'}
        id="qeerio-features"
      >
        <div
          className={clsx(classes.fullHeight, classes.madcapSection)}
        >
          <Section>
            <Features />
          </Section>
        </div>
      </ParallaxFeature>
      <div className={clsx(classes.fullHeight, classes.adidasSection)}>
        <Section>
          <FastCheckIn />
        </Section>
      </div>
      <ParallaxProfesionnal
        // backgroundImage={'../../../assets/images/qeerio/inventory/brandinginventory.webp'}
        id="qeerio-branding-inventory"
      >
        <div className={clsx(classes.fullHeight, classes.madcapSection)}>
          <Section>
            <Professional />
          </Section>
        </div>
      </ParallaxProfesionnal>
      <Section id="qeerio-reviews">
        <Reviews data={reviews} />
      </Section>
      <ParallaxPricing
        backgroundImage={'../../../assets/images/qeerio/imagepricing.webp'}
        id="qeerio-pricing"
      >
        <div className={clsx(classes.fullHeight, classes.madcapSection)}>
          <Section>
            <Pricing />
          </Section>
        </div>
      </ParallaxPricing>
      <Section>
        <Contact />
      </Section>
      <Divider />
    </div>
  );
};

export default Home;

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { colors, Grid, Box, IconButton } from '@material-ui/core';
import { IconAlternate } from 'components/molecules';
import { CardReviewLaura, CardReviewAdri, CardReviewMarcus } from 'components/organisms/inventory';
import { StaticImage } from 'gatsby-plugin-image';
import { Icon } from 'components/atoms';
import Swipe from 'swipejs';
import './review.css';

// const useStyles = makeStyles(() => ({
// }));

const Reviews = props => {
  const { data, className, ...rest } = props;
  // const classes = useStyles();

  React.useEffect(() => {
    var element = document.getElementById('slider');
    window.slider = new Swipe(element, {
      startSlide: 0,
      auto: 1000000,
      draggable: false,
      autoRestart: false,
      continuous: true,
      disableScroll: false,
      stopPropagation: true,
      callback: function (index, element) { },
      transitionEnd: function (index, element) { }
    });
  });

  return (
    <>
      <div className={className} data-aos="fade-up" {...rest} style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}>
        <Box mr={4}>
          <IconButton
            aria-label="previous review"
            style={{ backgroundColor: 'transparent' }}
            onClick={() => window.slider.prev()}>
            <Icon
              fontIconClass='fas fa-chevron-left'
            />
          </IconButton>
        </Box>
        <div id="slider" class="swipe">
          <div class="swipe-wrap">
            <div><CardReviewLaura
              style={{
                margin: 'auto'
              }}
              key={'laura'}
              className={'swiper-slide'}
              noBorder
              noShadow
              text="Amazing app, super easy to use, and crystal clear, especially if you have items in different places at the same time. The lean interface makes it easy to use, even for less tech-savvy people. I highly recommend it !"
              // text='The time saved before and after the event for the check-in management is insane. The time saved, the affordable prices and guest satisfaction makes it a no brainer.'
              icon={
                <IconAlternate
                  color={colors.indigo}
                  fontIconClass="fas fa-quote-right"
                />
              }
              authorName='Laura Van Nieuwenhove'
              authorTitle='Freelance event manager'
              authorPhoto={null}
            /></div>
            <div>
              <CardReviewAdri
                style={{
                  margin: 'auto'
                }}
                key={'charleslouis'}
                className={'swiper-slide'}
                noBorder
                noShadow
                text="Tracking the items on the move is a total game changer! No more double booking and the inventory is always up to date. Perfect tool for an event company, when we have to react quickly to the clients demands."
                icon={
                  <IconAlternate
                    color={colors.indigo}
                    fontIconClass="fas fa-quote-right"
                  />
                }
                authorName='Adrianna Hunt'
                authorTitle='Profirst, Project Manager'
                authorPhoto={null}
              /></div>
            <div><CardReviewMarcus
              style={{
                margin: 'auto'
              }}
              key={'jack'}
              className={'swiper-slide'}
              noBorder
              noShadow
              text="What a way to have a highly customisable inventory while being straightforward to use on daily basis. I definitely would recommend to anyone managing an inventory with items on the move !"
              icon={
                <IconAlternate
                  color={colors.indigo}
                  fontIconClass="fas fa-quote-right"
                />
              }
              authorName='Macrus Brown'
              authorTitle='Event You, CEO'
              authorPhoto={null}
            /></div>
          </div>
        </div>
        <Box ml={4}>
          <IconButton
            aria-label="next review"
            style={{ backgroundColor: 'transparent' }}
            onClick={() => window.slider.next()}>
            <Icon
              fontIconClass='fas fa-chevron-right'
            />
          </IconButton>
        </Box>
      </div>
      <Box mt={9}>
        <Grid container spacing={7} alignItems='center'>
          <Grid
            item
            xs={4}
            md={2}
          >
            <StaticImage
              style={{
                objectFit: 'contain',
                marginTop: '17px'
              }}
              src='../../../../../assets/images/qeerio/client-logo/bnp_paribas.png'
              alt="Bnp logo"
              placeholder="blurred"
              loading="lazy"
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <StaticImage
              style={{
                objectFit: 'contain',
                marginTop: '8px'
              }}
              src='../../../../../assets/images/qeerio/client-logo/deutsche_bank.png'
              alt="Deutschebank logo"
              placeholder="blurred"
              loading="lazy"
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <StaticImage
              style={{
                objectFit: 'contain',
                marginTop: '17px'
              }}
              src='../../../../../assets/images/qeerio/client-logo/profirst.png'
              alt="Profirst logo"
              placeholder="blurred"
              loading="lazy"
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <StaticImage
              style={{
                objectFit: 'contain',
                marginTop: '14px'
              }}
              src='../../../../../assets/images/qeerio/client-logo/redken.png'
              alt="Redken logo"
              placeholder="blurred"
              loading="lazy"
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <StaticImage
              style={{
                objectFit: 'contain',
                marginTop: '10px'
              }}
              src='../../../../../assets/images/qeerio/client-logo/dior.png'
              alt="Dior logo"
              placeholder="blurred"
              loading="lazy"
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <StaticImage
              style={{
                objectFit: 'contain',
                marginTop: '10px'
              }}
              src='../../../../../assets/images/qeerio/client-logo/porsche.png'
              alt="Porsche logo"
              placeholder="blurred"
              loading="lazy"
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

Reviews.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Reviews;

/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import Seo from '../../seo/inventory';
import Home from 'views/inventory/Home';
import Main from 'layouts/Inventory';
import WithLayout from 'WithLayout';

const InventoryPage = () => {
  return (
    <WithLayout
      seo={Seo}
      component={Home}
      layout={Main}
    />
  );
};

export default InventoryPage;

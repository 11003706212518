import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery, colors, Box, Button } from '@material-ui/core';
import { SectionHeader, IconAlternate } from 'components/molecules';
import { DescriptionListIcon } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  textWhite: {
    color: 'white',
    textTransform: 'uppercase'
  },
  textOrange: {
    color: 'rgb(255,100,83)',
    textTransform: 'uppercase'
  }
}));

const Professional = props => {
  const { className, ...rest } = props;
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <SectionHeader
        // title={<span className={classes.textWhite}><span className={classes.textOrange}>Immerse</span> your guests from the get-go!</span>}
        titleVariant="h3"
        subtitleVariant='h4'
        title={<Box mt={4}><span className={classes.textWhite}>The most{' '}<span className={classes.textOrange}>complete</span>{' '}and <span className={classes.textOrange}>intuitive</span> inventory application</span></Box>}
        ctaGroup={[
          <Box mt={5}>
            <Button variant="outlined" color="secondary" size="large" href="/inventory/features" aria-label="Get started now">
              Discover the features
            </Button>
          </Box>
        ]}
        disableGutter
        data-aos="fade-up"
      />
    </div>
  );
};

Professional.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string
};

export default Professional;

import { Description } from '@material-ui/icons';
import React from 'react';
import { Helmet } from 'react-helmet';

export default function SeoProductionbook() {
  const title = 'Qeerio | The smartest way to organize and track your inventory';
  const decription = 'A simple and complete app to manage your inventory. Try it for free!';
  const icon = require('../../src/assets/images/qeerio/qeeriologo.png').default;
  const image = require('../../src/assets/images/qeerio/inventory/logo.webp').default;
  const url = 'https://qeerio.com/inventory';

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en'
      }}
      title={title}
    >
      <meta charSet="utf-8" />
      <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      <meta name="theme-color" content="#ff6453" />
      <link rel="shortcut icon" href={icon}></link>
      <meta
        name="description"
        content={decription}
      />
      <meta
        name="robots"
        content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content={image}
      />
      <meta
        property="og:title"
        content={title}
      />
      <meta
        property="og:description"
        content={decription}
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={decription} />
      <meta
        property="twitter:image"
        content={image}
      />
      <link
        href="https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap"
        rel="preload"
        onload="this.onload=null;this.rel='stylesheet'"
        as="style"
      />
      <link rel="stylesheet" type="text/css" href="//fonts.googleapis.com/css?family=Yeseva+One" />
      <link href="https://fonts.googleapis.com/css2?family=Alegreya+Sans&family=Open+Sans&display=swap" rel="stylesheet" />
      <script
        src="https://kit.fontawesome.com/4c273e6d43.js"
        crossOrigin="anonymous"
      ></script>
    </Helmet>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery, Box, Button, Typography } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { StaticImage } from 'gatsby-plugin-image';

const useStyles = makeStyles(theme => ({
  textWhite: {
    color: 'white',
    textTransform: 'uppercase'
  },
  textOrange: {
    color: 'rgb(255,100,83)',
    textTransform: 'uppercase'
  },
  createEvent: {
    width: '90%'
  },
  iphoneImage: {
    width: '110%'
  }
}));

const FastCheckIn = props => {
  const { className, ...rest } = props;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <SectionHeader
        subtitle={<div><span className={classes.textWhite}>How does it work?</span></div>}
        subtitleVariant="h4"
        titleVariant="h3"
        title={<Box mt={1}><span className={classes.textWhite}>Track your items <span className={classes.textOrange}>on the move!</span></span></Box>}
        ctaGroup={[
          <Box
            mt={10}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Grid container spacing={7} justify="center" alignItems="center">
              <Grid key={1} item xs={12} sm={4} data-aos={'fade-up'}>
                <StaticImage
                  placeholder="blurred"
                  src='../../../../../assets/images/qeerio/inventory/bookitems.webp'
                  alt="Mac image"
                  className={classes.createEvent}
                  loading="lazy"
                />
                <Box display="flex" justify="center" alignItems="center" flexDirection="column" mt={4}>
                  <Box display="flex" justify="center" alignItems="center" flexDirection="column" mb={2}>
                    <Typography variant="h5">
                      <span><b><span className={classes.textOrange}>1.</span> Create an event and book items</b></span>
                    </Typography>
                    <Box mt={1}>
                      <Typography variant="body1" color="textSecondary" align="center">
                        Ideal to avoid double bookings
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid key={2} item xs={12} sm={4} data-aos={'fade-up'}>
                <Box display="flex" justify="center" alignItems="center" flexDirection="column">
                <StaticImage
                  placeholder="blurred"
                  src='../../../../../assets/images/qeerio/inventory/checkin.webp'
                  alt="Phone Image"
                  className={classes.createEvent}
                  loading="lazy"
                />
                  <Box display="flex" justify="center" alignItems="center" flexDirection="column" mt={2}>
                    <Typography variant="h5">
                      <span><b><span className={classes.textOrange}>2.</span> Check-in items</b></span>
                    </Typography>
                    <Box mt={1}>
                      <Typography variant="body1" color="textSecondary">
                        Trace items that leave the wearhouse
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid key={2} item xs={12} sm={4} data-aos={'fade-up'}>
                <Box display="flex" justify="center" alignItems="center" flexDirection="column">
                  <StaticImage
                    placeholder="blurred"
                    src='../../../../../assets/images/qeerio/inventory/checkout.webp'
                    alt="Phone Image"
                    className={classes.iphoneImage}
                    loading="lazy"
                  />
                  <Box display="flex" justify="center" alignItems="center" flexDirection="column" mt={2}>
                    <Typography variant="h5">
                      <span><b><span className={classes.textOrange}>3. </span>Check-out items</b></span>
                    </Typography>
                    <Box mt={1}>
                      <Typography variant="body1" color="textSecondary" align="center">
                        Mark items that come back to the wearhouse
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid key={2} item xs={12} sm={4} data-aos={'fade-up'}>
                <Box display="flex" justify="center" alignItems="center" flexDirection="column">
                  <StaticImage
                    placeholder="blurred"
                    src='../../../../../assets/images/qeerio/inventory/quantities.webp'
                    alt="Phone Image"
                    className={classes.iphoneImage}
                    loading="lazy"
                  />
                  <Box display="flex" justify="center" alignItems="center" flexDirection="column" mt={2}>
                    <Typography variant="h5">
                      <span><b><span className={classes.textOrange}>4. </span>Automatic quantities update</b></span>
                    </Typography>
                    <Box mt={1}>
                      <Typography variant="body1" color="textSecondary" align="center">
                        Missing items are detected and item quantities are modified to keep your inventory always up to date
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid key={2} item xs={12} sm={4} data-aos={'fade-up'}>
                <Box display="flex" justify="center" alignItems="center" flexDirection="column">
                  <StaticImage
                    placeholder="blurred"
                    src='../../../../../assets/images/qeerio/inventory/notify.webp'
                    alt="Phone Image"
                    className={classes.iphoneImage}
                    loading="lazy"
                  />
                  <Box display="flex" justify="center" alignItems="center" flexDirection="column" mt={2}>
                    <Typography variant="h5">
                      <span><b><span className={classes.textOrange}>5. </span>Get notified for future events</b></span>
                    </Typography>
                    <Box mt={1}>
                      <Typography variant="body1" color="textSecondary" align="center">
                        Qeerio notifies you for missing items booked for future events
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box mt={10}>
              <Button variant="outlined" color="secondary" size="large" href="https://inventory-backoffice.qeerio.com" aria-label="Get started now">
                Get started now
              </Button>
            </Box>
          </Box>
        ]}
        disableGutter
        data-aos="fade-up"
      />
    </div>
  );
};

FastCheckIn.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string
};

export default FastCheckIn;
